import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assests/logo.svg';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    scrollToTop();
    navigate(path);
    setIsOpen(false);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <nav className="navbar" onClick={() => setIsOpen(!isOpen)}>
      <div className="navbar-logo">
        <img src={Logo} alt="Company Logo" />
      </div>
      <button className="menu-toggle" onClick={toggleSidebar}>
        ☰
      </button>
      <ul className={`navbar-links ${isOpen ? 'open' : 'closed'}`}>
        <li onClick={() => handleNavigation('/')}>Home</li>
        <li onClick={() => handleNavigation('/privacy')}>Privacy</li>
        <li onClick={() => handleNavigation('/contact')}>Contact</li>
      </ul>
    </nav>
  );
};

export default Navbar;

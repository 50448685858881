import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import profilePicture from '../../assests/richard_gleeson.jpg'
import './ContactForm.css';


// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYLXadWJaKVMXLjx1QWXUsSks180IPDsQ",
  authDomain: "nimbus-tech-creators.firebaseapp.com",
  projectId: "nimbus-tech-creators",
  storageBucket: "nimbus-tech-creators.appspot.com",
  messagingSenderId: "1016162750712",
  appId: "1:1016162750712:web:6445fc63e23942375deb0d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    query: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addDoc(collection(db, 'users'), {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        query: formData.query,
        timestamp: new Date()
      });

      const emailParams = {
        name: formData.name,
        contact: formData.email,
        query: formData.query
      };

      await emailjs.send('service_g571k99', 'template_tk9b0ed', emailParams, 'HT2tpwFdxvq6fMfOH');
      toast.success('Your query has been submitted successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
      toast.error('Failed to submit your query. Please try again.');
    } finally {
      setLoading(false);
      setFormData({
        name: '',
        phone: '',
        email: '',
        query: ''
      });
    }
  };

  return (
    <div className="contact">
      <div className="contact-page">
        <div className='contact-first-draft'>
          <div className="testimonial-section">
            <div className="testimonial" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
              <img src={profilePicture} alt="Richard Gleeson" className="profile-picture" />
              <p>
                “I recently engaged with Nimbus Tech Creators for mobile app development and web UI design, and I couldn't be happier with the results. The team was professional, communicative, and delivered a stunning, user-friendly mobile app and a seamless web interface. Highly recommend their services! #GreatTeam #Excellent”
              </p>
              <h3>Richard Gleeson</h3>
              <div className="clutch-rating" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {[...Array(5)].map((_, index) => (
                  <FontAwesomeIcon key={index} icon={faStar} style={{ color: "#FFD43B" }} />
                ))}
              </div>
            </div>

            <div className="contact-form-container">
              <form onSubmit={handleSubmit}>
                <h1>Schedule a Free Call</h1>
                <div>
                  <label>How Can We Help You?</label>
                  <select
                    name="help"
                    value={formData.help}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>Select an option</option>
                    <option value="IT Consulting">IT Consulting</option>
                    <option value="Enterprise Software Development">Enterprise Software Development</option>
                    <option value="Mobile App Development">Mobile App Development</option>
                    <option value="Web Development">Web Development</option>
                    <option value="Dedicated Teams">Dedicated Teams</option>
                    <option value="eCommerce Development">eCommerce Development</option>
                    <option value="UI/UX Design">UI/UX Design</option>
                    <option value="DevOps">DevOps</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div>
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label>Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label>Message</label>
                  <textarea
                    name="query"
                    value={formData.query}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* Optional budget field */}
                <div>
                  <label>Budget (optional)</label>
                  <input
                    type="text"
                    name="budget"
                    value={formData.budget}
                    onChange={handleChange}
                    placeholder="Enter your budget"
                  />
                </div>
                <button className="btn_submit_contact" type="submit" disabled={loading}>Submit Now</button>
              </form>

              {loading && (
                <div className="loading-overlay">
                  <div className="spinner-container">
                    <div className="spinner"></div>
                  </div>
                </div>
              )}

              <ToastContainer />
            </div>
          </div>

          <div className="numbers-section">
            <h2>Our Work in Numbers</h2>
            <div className="numbers">
              <div className="number-item">
                <h3>3+</h3>
                <p>Years</p>
              </div>
              <div className="number-item">
                <h3>20+</h3>
                <p>Engineers</p>
              </div>
              <div className="number-item">
                <h3>50+</h3>
                <p>Projects</p>
              </div>
              <div className="number-item">
                <h3>30+</h3>
                <p>Satisfied Clients</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ContactForm;
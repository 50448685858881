import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faCogs, faShoppingCart, faMobileAlt, faGlobe, faStar, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import './Home.css';
import richardProfile from '../../assests/richard_gleeson.jpg';
import michaelProfile from '../../assests/michael.png';
import janeProfile from '../../assests/jane.png';
import callImage from '../../assests/call.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const firebaseConfig = {
  apiKey: "AIzaSyDYLXadWJaKVMXLjx1QWXUsSks180IPDsQ",
  authDomain: "nimbus-tech-creators.firebaseapp.com",
  projectId: "nimbus-tech-creators",
  storageBucket: "nimbus-tech-creators.appspot.com",
  messagingSenderId: "1016162750712",
  appId: "1:1016162750712:web:6445fc63e23942375deb0d"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Home = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    query: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addDoc(collection(db, 'users'), {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        query: formData.query,
        timestamp: new Date()
      });

      const emailParams = {
        name: formData.name,
        contact: formData.email,
        query: formData.query
      };

      await emailjs.send('service_g571k99', 'template_tk9b0ed', emailParams, 'HT2tpwFdxvq6fMfOH');
      toast.success('Your query has been submitted successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
      toast.error('Failed to submit your query. Please try again.');
    } finally {
      setLoading(false);
      setFormData({
        name: '',
        phone: '',
        email: '',
        query: ''
      });
    }
  };
  const reviews = [
    {
      id: 1,
      name: 'Richard Gleeson',
      review:
        '"I recently engaged with Nimbus Tech Creators for mobile app development, and I couldn\'t be happier with the results.The team was professional, communicative, and delivered a stunning, user- friendly mobile app.Highly recommend their services!"',
      profilePicture: richardProfile,
    },
    {
      id: 2,
      name: 'Jane Smith',
      review:
        '"Nimbus Tech Creators did an excellent job with our web UI design. They created a seamless and visually appealing interface that greatly improved our user experience. Their attention to detail and creativity exceeded our expectations."',
      profilePicture: janeProfile,
    },
    {
      id: 3,
      name: 'Michael Johnson',
      review:
        '"I was impressed by the level of expertise and dedication shown by the team at Nimbus Tech Creators. They delivered a high-quality software solution tailored to our specific needs. Their professionalism and commitment to excellence are truly remarkable."',
      profilePicture: michaelProfile,
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <div>
      <div className="intro-div">
        <section className="intro">
          <div className="intro-content">
            <h1>Craft Digital Excellence with AI-Driven Solutions</h1>
            <button className="book_call_btn" onClick={() => navigate('/contact')}>Book a Call</button>
          </div>
        </section>
      </div>
      <section className="services">
        <h2>Our Services</h2>
        <p className="services_tagline">Our software development company specializes in creating corporate and consumer applications utilizing our deep understanding of cutting-edge technologies.</p>
        <div className="services_grid">
          <div className="service">
            <FontAwesomeIcon icon={faBrain} size="3x" className="fa-icon" />
            <h3>AI Development Services</h3>
            <p>Leverage AI to automate processes and enhance decision-making with us. Our services include NLP, chatbot development, intelligent automation, and predictive analytics.</p>
          </div>
          <div className="service">
            <FontAwesomeIcon icon={faCogs} size="3x" className="fa-icon" />
            <h3>DevOps Services</h3>
            <p>Optimize your software development and deployment processes with our DevOps services. We provide tailored tool and platform selection, streamlining operations for optimal productivity.</p>
          </div>
          <div className="service">
            <FontAwesomeIcon icon={faShoppingCart} size="3x" className="fa-icon" />
            <h3>Ecommerce Development</h3>
            <p>Starting or growing your online business? We have ecommerce experts for starting or scaling online stores, specializing in Shopify, WooCommerce, Magento and custom builds.</p>
          </div>
          <div className="service">
            <FontAwesomeIcon icon={faMobileAlt} size="3x" className="fa-icon" />
            <h3>Mobile Development</h3>
            <p>Whether building a native mobile app for iOS or Android, or a cross-platform solution using React Native, we have the skills and experience to bring your vision to life.</p>
          </div>
          <div className="service">
            <FontAwesomeIcon icon={faGlobe} size="3x" className="fa-icon" />
            <h3>Web Development</h3>
            <p>From custom websites to complex web apps, our team of experienced web developers delivers custom websites, using scalable, secure, and maintainable technology.</p>
          </div>
          <div className="service">
            <FontAwesomeIcon icon={faLaptopCode} size="3x" className="fa-icon" />
            <h3>Software Development</h3>
            <p>We offer comprehensive software development services, including custom software development, system integration, and software maintenance. Let us build the solution you need.</p>
          </div>
        </div>
      </section>
      <div className="numbers-section">
        <h2>Our Work in Numbers</h2>
        <div className="numbers">
          <div className="number-item">
            <h3>3+</h3>
            <p>Years</p>
          </div>
          <div className="number-item">
            <h3>20+</h3>
            <p>Engineers</p>
          </div>
          <div className="number-item">
            <h3>50+</h3>
            <p>Projects</p>
          </div>
          <div className="number-item">
            <h3>30+</h3>
            <p>Satisfied Clients</p>
          </div>
        </div>
      </div>
      <div className="testimonial-slider">
        <Slider {...settings}>
          {reviews.map((review) => (
            <div
              key={review.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '10px',
                margin: '20px 0',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                width: '100%',
                textAlign: 'left' // Set default text alignment for the rest of the content
              }}
            >
              {/* Render the profile picture */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '20px'
                }}
              >
                <img
                  src={review.profilePicture}
                  alt={`${review.name}'s profile`}
                  style={{
                    maxWidth: '100px',
                    borderRadius: '50%'
                  }}
                />
              </div>
              <div style={{ fontSize: '1rem', color: '#333', textAlign: 'center' }}>
                {review.review}
              </div>
              <div style={{ fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
                {review.name}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px'
                }}
              >
                {[...Array(5)].map((_, index) => (
                  <FontAwesomeIcon
                    key={index}
                    icon={faStar}
                    style={{
                      fontSize: '1.2rem',
                      color: '#ffa500',
                      margin: '0 2px',
                    }}
                  />
                ))}
              </div>
            </div>
          ))}

        </Slider>
      </div>
      <div className="home-section">
        <div className="home-text">
          <div className="fixed-bottom-image-container">
            <img src={callImage} alt="Call Us" className="fixed-bottom-image" />
          </div>
          <div className='call-image-text'>
            <h2>We will get back to you soon!</h2>
            <ul>
              <li>Leave the required information and your queries in the given contact us form.</li>
              <li>Our team will contact you to get details on the questions asked, meanwhile, we might ask you to sign an NDA to protect our collective privacy.</li>
              <li>The team will get back to you in 24 hours.</li>
            </ul>
          </div>
        </div>
        <div className="home-form-container">
          <form onSubmit={handleSubmit}>
            <h1>Let's Talk!</h1>
            <div>
              <label>How Can We Help You?</label>
              <select
                name="help"
                value={formData.help}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select an option</option>
                <option value="IT Consulting">IT Consulting</option>
                <option value="Enterprise Software Development">Enterprise Software Development</option>
                <option value="Mobile App Development">Mobile App Development</option>
                <option value="Web Development">Web Development</option>
                <option value="Dedicated Teams">Dedicated Teams</option>
                <option value="eCommerce Development">eCommerce Development</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="DevOps">DevOps</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label>Full Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>Phone</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>Message</label>
              <textarea
                name="query"
                value={formData.query}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>Budget (optional)</label>
              <input
                type="text"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                placeholder="Enter your budget"
              />
            </div>
            <button className="btn_submit_home" type="submit" disabled={loading}>Submit Now</button>
          </form>
          {loading && (
            <div className="loading-overlay">
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            </div>
          )}
          <ToastContainer />
        </div>
      </div>
    </div >
  );
};

export default Home;

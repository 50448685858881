import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar.jsx';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home/Home.jsx';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy.jsx';
import ContactForm from './components/Contact/ContactForm.jsx';
import Footer from './components/Footer/Footer.jsx';
import ImageComponent from './components/ImageComponent/ImageComponent.jsx';

const App = () => {
    const [showFooter, setShowFooter] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/certificate') {
            setShowFooter(false);
        } else {
            setShowFooter(true);
        }
    }, [location]);

    return (
        <div className="App">
            <Navbar />
            <div className="content-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/contact" element={<ContactForm />} />
                    <Route path="/certificate" element={<ImageComponent />} />
                </Routes>
            </div>
            {showFooter && <Footer />} {/* Conditionally render the Footer */}
        </div>
    );
}

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;

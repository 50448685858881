import React from 'react';
import './ImageComponent.css';

// Import all images
import image1 from '../../assests/Gauri Mehta Internship Certificate.png';

const images = [
    { src: image1, alt: 'Description for image 1' }
    // Add more images here
];

const ImageComponent = () => {
    return (
        <div className="image-gallery">
            {images.map((image, index) => (
                <div key={index} className="image-container">
                    <img src={image.src} alt={image.alt} className="image" />
                </div>
            ))}
        </div>
    );
};

export default ImageComponent;

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import your CSS file
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// Fix Leaflet's default icon issue with Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const Footer = () => {
    const melbournePosition = [-37.8136, 144.9631]; // Melbourne coordinates

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <footer className='footer'>
            <div className="footer-div">
                <div className="footer-content">
                    <h3 className="footer-heading">NIMBUS TECH CREATORS</h3>
                    <p className='footer-body'>Simplifies Life Using Technology</p>
                    <div className="footer-line"></div> {/* Grey line */}
                    <div className="footer-links">
                        <a href="https://www.linkedin.com/company/nimbus-tech-creators/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="https://www.instagram.com/nimbus_tech_creators" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.tiktok.com/@nimbustechcreators" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTiktok} />
                        </a>
                        <div className="footer-email">
                            <a href="mailto:support@nimbustechcreators.com">
                                <FontAwesomeIcon icon={faEnvelope} className="footer-email-icon" />
                                <span>support@nimbustechcreators.com</span>
                            </a>
                        </div>
                    </div>
                    <nav className="footer-nav">
                        <Link to="/" onClick={scrollToTop}>Home</Link>
                        <Link to="/privacy" onClick={scrollToTop}>Privacy</Link>
                        <Link to="/contact" onClick={scrollToTop}>Contact</Link>
                    </nav>
                    <p className='footer-copyright'>Copyright © 2024 Nibus Tech Creators. All rights reserved.</p>
                </div>
                <div className="footer-map">
                    <MapContainer center={melbournePosition} zoom={13} scrollWheelZoom={false} dragging={false}>
                        <TileLayer
                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                            attribution='&copy; <a href="https://carto.com/">Carto</a> contributors, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={melbournePosition}>
                            <Popup>Melbourne, VIC 3000, Australia</Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
